<template>
  <datepicker
    ref="datepickerRef"
    :auto-apply="autoApply"
    :close-on-clear-value="false"
    :disabled="disabled"
    :disabled-dates="disabledDates"
    :enable-time-picker="false"
    :locale="locale"
    :max-date="maxRangeDate"
    :max-range="DATE_PICKER_MAX_RANGE"
    :min-date="minRangeDate"
    :min-range="1"
    :model-value="modelValue"
    :month-change-on-scroll="false"
    :start-date="startDate"
    focus-start-date
    hide-offset-dates
    keep-action-row
    month-name-format="long"
    multi-calendars="2"
    position="left"
    prevent-min-max-navigation
    range
    teleport="body"
    uid="desktop-picker"
    @open="emitCalendarChanged"
    @update-month-year="onUpdateMonthYear"
    @update:model-value="updateValue"
    @internal-model-change="onInternalValueChange">
    <template #day="{ day, date }">
      <span
        v-if="day !== 0"
        class="dp__day"
        :data-testid="`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`">
        {{ day }}
      </span>
    </template>

    <template #dp-input="{ value }">
      <slot name="displayed-input">
        <ai-input
          :disabled="disabled"
          :label="$t('search.filter.label.dates')"
          :model-value="value" />
      </slot>
    </template>

    <template v-if="typeof minimumStayDuration === 'number'" #left-sidebar>
      <ai-tool-tip
        :label="
          $t('search.datePicker.minimumStayDuration', { minimumStayDuration })
        "
        :size="18"
        align-item="center"
        background="aqua-blue-100"
        fill-color="aqua-blue-700"
        text-color="aqua-blue-700"
        variant="calendar" />
    </template>

    <template
      #month-year="{
        month,
        year,
        months,
        years,
        instance,
        monthPicker,
        updateMonthYear,
      }">
      <ai-multi-date-picker-month-year
        :custom-props="customProps"
        :instance="instance"
        :month="month"
        :month-picker="monthPicker"
        :months="months"
        :update-month-year-method="updateMonthYear"
        :year="year"
        :years="years" />
    </template>
    <template #action-row="{ internalModelValue }">
      <ai-multi-date-picker-action-row
        ref="multiDatePickerActionRow"
        :custom-props="customProps"
        :internal-model-value="internalModelValue"
        @clear-dates="clearDates"
        @select-internal-dates="intervalDate" />
    </template>
  </datepicker>

  <div v-if="!bypassTeleport" id="datepicker-template" />
</template>

<script lang="ts" setup>
import type { DatePickerInstance } from '@vuepic/vue-datepicker';
import datepicker from '@vuepic/vue-datepicker';

import { DATE_PICKER_MAX_RANGE } from '~/core';

import type AiMultiDatePickerActionRow from '../AiMultiDatePickerActionRow.vue';
import type AiMultiDatePickerMonthYear from '../AiMultiDatePickerMonthYear.vue';

const datepickerInternalValue = useDatepickerInternalValue();

type DesktopPickerProps = {
  modelValue: Date | Date[];
  customProps: Record<string, unknown>;
  autoApply?: boolean;
  bypassTeleport?: boolean;
  disabled?: boolean;
  minimumStayDuration?: number;
};
const props = withDefaults(defineProps<DesktopPickerProps>(), {
  autoApply: () => true,
  minimumStayDuration: undefined,
});

type DesktopPickerEmits = {
  (event: 'update:modelValue', value: Date | Date[]): void;
  (event: 'calendar-changed', value: DatePickerInstance | undefined): void;
};
const emit = defineEmits<DesktopPickerEmits>();

const { locale } = useI18n();

const multiDatePickerActionRow = ref<typeof AiMultiDatePickerActionRow>();

const updateValue = (newValue: Date | Date[]) => {
  if (
    Array.isArray(newValue) &&
    newValue.length === 2 &&
    newValue[0].getTime() === newValue[1].getTime()
  ) {
    nextTick(() => {
      openDatePicker();
    });
    return;
  }

  emit('update:modelValue', newValue);
};

const lastInternalValue = ref<Date[]>();
const selectDate = ref<Date[]>([]);

const intervalDate = (dates: Date[]) => {
  selectDate.value = dates;
};

const maxRangeDate = computed(() => {
  const isFilled =
    Array.isArray(props.modelValue) && props.modelValue.length === 2;

  if (!selectDate.value.length || isFilled) return '';

  const date = new Date(selectDate.value[0]);
  date.setDate(date.getDate() + DATE_PICKER_MAX_RANGE);

  return date;
});

const minRangeDate = computed(() => {
  if (!selectDate.value.length) {
    return new Date();
  }

  const today = new Date();
  const date = new Date(selectDate.value[0]);

  date.setDate(date.getDate() - DATE_PICKER_MAX_RANGE);
  return date < today ? today : date;
});

// Since it's a ref, it must be named exactly the same as the ref.
// It means we CANT shorthand the affectation to the customProps variable
const datepickerRef = ref<DatePickerInstance>();
const clearDates = () => {
  if (!datepickerRef.value) return;

  datepickerRef.value.clearValue();
  datepickerInternalValue.updateDate([]);
  selectDate.value = [];
};

const startDate = computed(() => {
  const defaultStartDate = new Date();

  if (!props.modelValue) {
    return defaultStartDate;
  }

  const firstDate = Array.isArray(props.modelValue)
    ? props.modelValue[0]
    : props.modelValue;

  return firstDate ?? defaultStartDate;
});

const openDatePicker = () => {
  if (!datepickerRef.value) return;

  datepickerRef.value.openMenu();
  emitCalendarChanged();
};

const disabledDates = computed(() => {
  const internalValue = multiDatePickerActionRow.value?.internalValue;

  if (!internalValue || !props.minimumStayDuration) return;

  const dates = [];

  for (let i = 1; i < props.minimumStayDuration; i++) {
    const futureDate = new Date(internalValue);
    const previousDate = new Date(internalValue);

    futureDate.setDate(futureDate.getDate() + i);
    previousDate.setDate(previousDate.getDate() - i);

    dates.push(futureDate, previousDate);
  }

  return dates;
});

const onInternalValueChange = (newInternalValue: Date[]) => {
  if (!lastInternalValue.value) {
    lastInternalValue.value = newInternalValue;
    emitCalendarChanged();

    return;
  }

  if (!newInternalValue) {
    lastInternalValue.value = undefined;
  }

  if (
    lastInternalValue.value &&
    newInternalValue[0] && // Start date selected
    !newInternalValue[1] // End date not selecte
  ) {
    const formattedStartDate =
      newInternalValue[0].getDate() +
      '-' +
      newInternalValue[0].getMonth() +
      '-' +
      newInternalValue[0].getFullYear();

    const formattedLastInternalStartDate =
      lastInternalValue.value[0].getDate() +
      '-' +
      lastInternalValue.value[0].getMonth() +
      '-' +
      lastInternalValue.value[0].getFullYear();

    if (formattedLastInternalStartDate === formattedStartDate) {
      clearDates();
    }
  }

  lastInternalValue.value = newInternalValue;
  emitCalendarChanged();
};

const onUpdateMonthYear = () => {
  setTimeout(() => {
    emitCalendarChanged();
  }, 100);
};

const emitCalendarChanged = () => {
  emit('calendar-changed', datepickerRef?.value);
};

defineExpose({
  datepicker: datepickerRef,
  openDatePicker,
});
</script>
